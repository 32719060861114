import React from 'react'

const Functions = () => {
    return (
        <div style={{marginLeft: '500px'}}>
            This is functions page.
        </div>
    )
}

export default Functions
