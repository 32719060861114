import React from 'react'
import GridWrapper from '../../components/common/GridWrapper/GridWrapper'

const Hosting = () => {
    return (
        <GridWrapper>
            Hosting
        </GridWrapper>
    )
}

export default Hosting
