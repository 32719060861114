import React from 'react'

const MachineLearning = () => {
    return (
        <div style={{marginLeft: '500px'}}>
            This is machine learning page.
        </div>
    )
}

export default MachineLearning
